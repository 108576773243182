.App {
  text-align: center;
}

.monospaced {
  font-family: "Consolas", monospace;
}

.no-padding {
  /*padding: 0 !important;*/
  /*padding-left: 0 !important;*/
  /*padding-right: 0 !important;*/
}
